import {
  Badge,
  GridIcon,
  Group,
  House,
  NotePad,
  Paper,
  PlaySquare,
  TruckL,
  TruckS,
  Users,
} from "../../assets/svg/icons";
import { pagePath, pageRoutes } from "../../pages/pagePath";

export const navlinksBundle = {
  admin: [
    {
      Icon: PlaySquare,
      link: pagePath.admin_manage_course,
      label: "manage course",
    },
    {
      Icon: House,
      link: pagePath.admin_manage_institution,
      label: "manage institution",
    },
  ],
  institution: [
    {
      Icon: House,
      link: pagePath.institution_home,
      label: "Institution",
    },
  ],
};
