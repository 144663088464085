import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#1A73E8",
    },
    error: {
      main: "#ee4747",
    },
  },
  text: {
    color: "#212121",
  },
  typography: {
    fontFamily: "var(--ffy-regular),sans-serif",

    h1: {
      fontFamily: "var(--ffy-heavy),sans-serif",
      fontSize: 34,
      fontWeight: "var(--fwt-heavy)",
      textTransform: "capitalize",
    },
    h2: {
      fontFamily: "var(--ffy-bold),sans-serif",
      fontSize: 28,
      fontWeight: "var(--fwt-bold)",
      textTransform: "capitalize",
    },
    h3: {
      fontFamily: "var(--ffy-bold),sans-serif",
      fontSize: 24,
      fontWeight: "var(--fwt-bold)",
      textTransform: "capitalize",
    },
    h4: {
      fontFamily: "var(--ffy-bold),sans-serif",
      fontSize: 20,
      fontWeight: "var(--fwt-semi-bold)",
      textTransform: "capitalize",
    },
    h5: {
      fontFamily: "var(--ffy-bold),sans-serif",
      fontSize: 16,
      fontWeight: "var(--fwt-semi-bold)",
      textTransform: "capitalize",
    },
    h6: {
      fontFamily: "var(--ffy-semi-bold), sans-serif",
      fontSize: 16,
      fontWeight: "var(--fwt-medium)",
      textTransform: "capitalize",
    },
    subtitle1: {
      fontFamily: "var(--ffy-regular) ,sans-serif",
      fontSize: 16,
      color: "var(--clr-grey)",
      textTransform: "capitalize",
      lineHeight: 1.2,
    },
    subtitle2: {
      fontFamily: "var(--ffy-regular) ,sans-serif",
      fontSize: 14,
      color: "var(--clr-grey-200)",
      textTransform: "capitalize",
      lineHeight: 1.2,
    },
    p1: {
      fontFamily: "var(--ffy-regular) ,sans-serif",
      fontSize: 16,
      color: "var(--clr-grey)",
      display: "block",
    },
    p2: {
      fontFamily: ["var(--ffy-regular)", "sans-serif"].join(","),
      fontSize: 14,
      display: "block",
    },
    caption: {
      display: "block",
      fontFamily: ["var(--ffy-regular)", "sans-serif"].join(","),
      fontSize: 12,
      color: "var(--clr-grey-400)",
      marginLeft: 0,
      lineHeight: 1,
    },
    button: {
      fontFamily: "var(--ffy-semi-bold), sans-serif",
      fontSize: "var(--fsz-xx-small)",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
          padding: "12px 24px",
          textTransform: "capitalize",
          fontWeight: "normal",
        },
        contained: {
          color: "#fff",
        },
        outlined: {
          color: "var(--clr-blue)",
          padding: "10.5px 24px",
          border: "2px solid var(--clr-blue)",
          ":hover": {
            border: "2px solid var(--clr-blue)",
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "var(--ff-hv-md)",
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "var(--clr-white)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: 12,
          borderRadius: "4px",
          fontFamily: "var(--ffy-regular)",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: "5px 0 0 0",
          fontSize: "var(--fsz-xx-small)",
          fontFamily: "var(--ffy-medium)",
          color: "var(--clr-red-300) !important",
          textTransform: "lowercase",
          textAlign: "left !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "48px",
          fontSize: "var(--fsz-medium)",
        },
        input: {
          fontFamily: "var(--ffy-medium)",
          fontSize: "var(--fsz-small)",
          "::placeholder": {
            color: "clr-grey",
          },
          color: "var(--clr-black)",
        },
        notchedOutline: {
          borderColor: "var(--clr-ash-100)",
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontFamily: "var(--ffy-regular)",
          fontSize: "var(--fsz-x-small)",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          borderRadius: "7px",
          fontFamily: "var(--ffy-regular)",
          color: "var(--clr-grey-200)",
          fontSize: "var(--fsz-x-small)",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          color: "var(--clr-grey-300)",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: "fit-content",
          borderBottom: "1px solid #e8e8e8",
          "& .MuiTabs-indicator": {
            backgroundColor: "var(--clr-blue)",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "var(--ffy-medium)",
          fontSize: "var(--fsz-xx-small)",
          textTransform: "capitalize",
          lineHeight: 2,
          color: "var(--clr-grey-200)",
          padding: "10px 20px",
          "&:hover": {
            color: "var(--clr-blue)",
            opacity: 1,
          },
          "&.Mui-selected": {
            color: "var(--clr-blue)",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "#d1eaff",
          },
        },
      },
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 15,
          color: "var(--clr-grey-400)",
        },
        "&.Mui-focused": {
          fontSize: 12,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: "var(--ffy-regular),sans-serif",
          fontSize: 14,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "var(--fsz-xx-small)",
          color: "var(--clr-grey-200)",
          textTransform: "capitalize",
          marginBottom: "4px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          fontSize: "var(--fsz-)",
          color: "var(--clr-ash-300)",
        },
      },
    },
  },
});
