import { useEffect } from "react";
import Pages from "./pages/pages";
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from "@react-oauth/google";
function App() {
  // useEffect(() => {
  //   ReactGA.initialize("G-4FTTKMY4YY");
  // }, []);

  return (
    // <GoogleOAuthProvider
    //   clientId={
    //     "http://556742443652-tj06713duapd68o3dipkprq76mumrr9i.apps.googleusercontent.com"
    //   }
    // >
    <Pages />
    // </GoogleOAuthProvider>
  );
}

export default App;
