export const pagePath = {
  // #######################################
  // admin

  admin: "/admin",
  admin_login: "/admin/login",
  admin_manage_course: "/admin/manage-course",
  admin_new_course: "/admin/manage-course/new-course",
  admin_view_course: "/admin/manage-course/course",
  admin_manage_institution: "/admin/manage-institution",
  admin_view_institution: "/admin/manage-institution/view-institution",
  // admin_manage_institution: "/admin/manage-institution/insitution",

  // #######################################
  // user

  user: "/user",
  user_login: "/login",
  user_home: "/user/home/",
  user_course: "/user/my-course/",
  user_course_unit: "/user/my-course/unit",
  user_course_module: "/user/my-course/module",
  user_course_module_test: "/user/my-course/module/test",

  institution: "/institution",
  institution_home: "/institution/home",
  institution_login: "/institution/login",

  landing: "land",
};
